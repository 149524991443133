import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import PageTitle from "../components/page-title/page-title";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import "./preguntas-frecuentes.scss";
import { Link } from "gatsby";

export default function Faqs() {
    return (
        <React.Fragment>
            <SEO title="Preguntas frecuentes" />
            <Layout>
                <PageTitle text="Preguntas Frecuentes" />
                <Accordion allowMultipleExpanded={false}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Porqué utilizar los servicios de{" "}
                                <Link to="/">
                                    <strong>
                                        <u>negligenciasdeabogados.net</u>
                                    </strong>
                                </Link>{" "}
                                ? ¿Cuál es su valor añadido?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                El conjunto de los{" "}
                                <strong>servicios gratuitos</strong> de nuestra
                                web (información y consejos ofrecidos en los
                                posts, cuestionario de autovaloración, foro de
                                consultas del blog y cuéntanos tu caso) así como
                                los <strong>servicios premium</strong>{" "}
                                (asesoramiento telefónico y dictamen) son un
                                novedoso y amplio abanico de posibilidades para
                                todo cliente que se sienta insatisfecho e
                                incluso perjudicado por la mala praxis de su
                                abogado, siendo nuestro objetivo ofrecerte{" "}
                                <Link to="/quienes-somos/">
                                    <u>soluciones personalizadas</u>
                                </Link>{" "}
                                para que una vez tengas un conocimiento claro de
                                cómo está su caso, puedas tomar una decisión al
                                respecto: o reclamar por mala praxis de tu
                                abogado o intentar minimizar el perjuicio que se
                                te pueda estar ocasionando o que ya se te ha
                                producido.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Para que me sirve rellenar el cuestionario de
                                autovaloración?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                El{" "}
                                <Link to="/cuestionario-autovaloracion/">
                                    <strong>
                                        <u>cuestionario</u>
                                    </strong>
                                </Link>{" "}
                                es una herramienta <strong>gratuita</strong> que
                                te ofrecemos enfocada a una{" "}
                                <strong>
                                    autovaloración inicial sobre la viabilidad
                                </strong>{" "}
                                de tu reclamación, no obstante para obtener una
                                información más concreta sobre las{" "}
                                <Link to="/que-hacer-cuando-un-abogado-no-cumple-con-su-trabajo/">
                                    <strong>
                                        <u>diferentes vías</u>
                                    </strong>
                                </Link>{" "}
                                para iniciarla, el momento adecuado así como una
                                aproximada cuantificación económica es
                                fundamental el{" "}
                                <strong>asesoramiento telefónico</strong> por
                                uno de nuestros expertos.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿En qué consiste el asesoramiento telefónico?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Como cada caso y cada cliente es diferente,
                                nuestros expertos en responsabilidad civil
                                profesional se pondrán en{" "}
                                <Link to="/consulta-experto/">
                                    <strong>
                                        <u>contacto contigo</u>
                                    </strong>
                                </Link>
                                .
                            </p>

                            <p>
                                Mediante una serie de preguntas sencillas te
                                ofreceran una serie de opiniones y consejos
                                personalizados según tus necesidades de
                                información: p.ej. cómo conseguir la
                                documentación que necesito, cómo saber el estado
                                del procedimiento, consecuencias a futuro del
                                error del abogado, etc para finalmente emitir
                                una opinión sobre las diferentes opciones que
                                tiene el cliente.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    
                    {/* <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Qué opciones tengo tras la consulta?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                En un plazo de 48 horas recibirá un correo
                                electrónico con un breve resumen de la consulta
                                y de las diferentes opciones: contratar el
                                dictamen, contratar un nuevo abogado para que
                                sustituya al anterior y/o le reclame, defensa
                                jurídica, reclamar ante el colegio de abogados,
                                etc
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem> */}
                    {/* <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Obtendré factura?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Una vez efectuada la compra del servicio que
                                hayas elegido, recibirás un documento de pago
                                expedido por la plataforma. Terminado el
                                servicio se te remitirá factura en forma.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem> */}

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Para qué me sirve tener un dictamen?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                El dictamen es un documento con valor jurídico y
                                que te ahorrará mucho tiempo/dinero a la hora de
                                reclamar a tu abogado, sin necesidad de acudir a
                                otras vías para asesorarte inicialmente. Más
                                información{" "}
                                <Link to="/dictamen-documento-valioso-para-reclamar/">
                                    <strong>
                                        <u>aquí</u>
                                    </strong>
                                </Link>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    {/* <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Y si cambio de opinión y ya no quiero que me
                                llamen?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                No hay problema, si cambias de opinión puedes
                                anular el servicio dentro de las{" "}
                                <strong>6 horas siguientes</strong> tras la
                                confirmación por el experto de la hora de
                                contacto que hayáis acordado.
                            </p>
                            <p>
                                Una vez recibas confirmación de la anulación del
                                servicio,{" "}
                                <strong>
                                    el experto te indicará las instrucciones
                                </strong>{" "}
                                para que solicites la devolución del importe
                                establecido en las{" "}
                                <Link to="/condiciones-legales/">
                                    condiciones legales
                                </Link>{" "}
                                y en el plazo máximo de 5 días te devolveremos
                                la diferencia.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Y si no puedo avisar 6 horas antes o me es
                                imposible atender al experto?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Nos hacemos cargo que no puedas atender al
                                experto por las razones que sean.
                            </p>
                            <p>
                                Tras contactarte sin éxito el experto ya no
                                estará disponible para ti pero recibirás una
                                comunicación dando por cancelado el servicio,
                                con las instrucciones para que solicites la
                                devolución del importe establecido en las{" "}
                                <Link to="/condiciones-legales/">
                                    condiciones legales
                                </Link>{" "}
                                y en el <strong>plazo máximo de 5 días</strong>{" "}
                                te devolveremos la diferencia.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Puedo comprar la consulta sin tener que
                                rellenar el formulario?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Por supuesto, pinchando en{" "}
                                <Link to="/consulta-experto/">
                                    comprar consulta
                                </Link>
                                . No obstante es recomendable intentarlo para
                                ofrecer información sobre su caso a nuestros
                                expertos antes de llamarle.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem> */}
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Qué seguridad tengo de que mi consulta sea
                                confidencial?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Absoluta nuestros expertos no necesitan conocer
                                sus datos personales ni los del Abogado a
                                reclamar así como tampoco el Organismo o Juzgado
                                que puedan conocer de su caso.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    {/* <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Que opciones tengo tras la consulta?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                En un plazo de 48 horas recibirá un correo
                                electrónico con un breve resumen de la consulta
                                y de las diferentes opciones: contratar el
                                dictamen, defensa jurídica, reclamar ante el
                                colegio de abogados, etc.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Puedo contratar al experto que me ha asesorado
                                para interponer una reclamación o para demandar
                                por negligencia a un abogado?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Esa posibilidad no la contemplamos entre
                                nuestros servicios, pero el experto que atienda
                                tu consulta o emita tu dictamen, dependiendo de
                                la viabilidad y la cuantía económica de tu caso,
                                te informará sobre las distintas opciones que
                                tienes al respecto.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem> */}

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                ¿Tendré algún problema con mi abogado si tiene
                                conocimiento que he consultado mi caso con un
                                experto de negligenciasdeabogados.net?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                En absoluto, la última reforma del Código
                                Deontológico de la Abogacía del año 2019 regula
                                en su art. 12.A.7. el denominado <strong>derecho del
                                cliente a una segunda opinión</strong>.{" "}
                            </p>
                            <p>
                                Este derecho <strong>no presupone la renuncia del
                                cliente a su abogado actual</strong> ni tampoco que el
                                experto tenga la obligación de comunicar a tu
                                abogado que te va a asesorar o emitir el
                                dictamen.{" "}
                            </p>
                            <p>
                                De todas formas, para tu tranquilidad, te
                                {" "}<strong>garantizamos una confidencialidad total</strong> pues
                                nuestros expertos nunca contactarán con tu
                                abogado pues no es necesario para asesorarte.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </Layout>
        </React.Fragment>
    );
}
